<template>
    <div class="">
        <div class="">
            <section class="content-header pt-s-5 pl-0 pr-0 pt-0">
                <div class="renewalsection">
                    <div class="row">
                        <div class="col-md-2  col-xs-2 col-sm-2 m-dash-logo">
                            <img :src="'/' + this.getSiteProps('clubdashboard.logo')" v-if="'/' + this.getSiteProps('clubdashboard.logo')" />
                        </div>
                        <div class="col-md-7 pl-3">
                            <h3 class="renew-title">
                                Welcome to Your {{this.getSiteProps('general.admin-team') || 'Club'}} Dashboard
                            </h3>
                                <span class="renew-subtitle"
                    >{{this.getSiteProps('memberdashboardbanner.bannersubtitle')}}</span
                >
                        </div>
                        <div class="col-md-3 mt-4" v-if="membershipExpiring && !renewDisabled && !disableRenew">
                            <button
                                type="button"
                                class="btn btn-primary btn-renew"
                                @click="renewMemberShip"
                            >
                                <i class="fas fa-sync-alt"></i> Renew {{this.getSiteProps('general.admin-team') || 'Club'}}
                            </button>
                        </div>
                    </div>
                </div>
            </section>
              <div class="row pl-4 pr-4">
          <div class="col-md-3 col-sm-6 col-12 r-pad l-pad">
            <div class="info-box bg-gradient-info shadow-sm">
              <span class="info-box-icon"><i class="fas fa-user"></i></span>

              <div class="info-box-content">
                <span class="info-box-text">Membership Number</span>
                <span class="info-box-number" v-if="profileData.info.registration.member.confirmation_code">{{
                  profileData.info.registration.member.confirmation_code
                }}</span>
              </div>
              <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
          </div>

          <div class="col-md-3 col-sm-6 col-12 r-pad l-pad">
            <div class="info-box bg-gradient-success shadow-sm">
              <span class="info-box-icon"
                ><i class="far fa-address-card"></i
              ></span>

              <div class="info-box-content">
                <span class="info-box-text">Member Type</span>
                <span class="info-box-number" v-if="profileData.info.registration.membership">{{
                   profileData.info.registration.membership.name
                }}</span>
              </div>
              <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
          </div>

          <div class="col-md-3 col-sm-6 col-12 r-pad l-pad">
            <div class="info-box bg-gradient-warning shadow-sm">
              <span class="info-box-icon"
                ><i class="far fa-calendar-alt"></i
              ></span>

              <div class="info-box-content">
                <span class="info-box-text">Expiration Date </span>
                <span class="info-box-number" v-if="profileData.info.registration.membership">{{
                    profileData.info.registration.membership.period != 999 ? usdate(profileData.info.registration.valid_thru) : 'No Expiration'
                }}</span>
              </div>
              <!-- /.info-box-content -->
            </div>
            <!-- /.info-box -->
          </div>

                <div class="col-md-3 col-sm-6 col-12 r-pad l-pad" v-if="this.dashboard_status.membershipstatus">
                  <div class="info-box bg-gradient-gray shadow-sm">
              <span class="info-box-icon"
              ><i class="fas fa-clipboard-check"></i
              ></span>

                    <div class="info-box-content">
                <span class="info-box-text">Membership Status
                  <div class="d-inline ml-2" id="tooltip"><i class="fas fa-info-circle"></i></div></span>
                      <span class="info-box-number float-left mr-1"> {{ profileData.info.registration.reg_status  === 'Active' ? 'Current' :
                          (profileData.info.registration.reg_status === 'Expired' ? 'Expired' : profileData.info.registration.reg_status ) }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-6 col-12 r-pad l-pad" v-if="this.dashboard_status.waiverstatus">
            <div class="info-box shadow-sm" :class="profileData.info.waivers.length ? 'bg-gradient-success' : 'bg-gradient-secondary'">
              <span class="info-box-icon"
                ><i class="fas fa-clipboard-check"></i
              ></span>

              <div class="info-box-content">
                <span class="info-box-text">Waiver Status</span>
                <!-- <span class="info-box-number"> {{ profileData.info.registration.transaction_id != null ? "Signed" : "N/A" }} </span> -->
                <span class="info-box-number"> {{ profileData.info.waivers.length ? "Signed" : "N/A" }} </span>
              </div>
            </div>
          </div>
        </div>
            <div class="video-wrapper" :class="{ 'mt-1': subdomain=='shooting' }">
                <div class="row">
                    <div class="col-md-12 status_bar pl-0 pr-0 mb-3">
                        <div class="row dashboard-widgets">
                            <div class="col-md-6 r-pad l-pad" :class="{ '': subdomain=='shooting' }">
                                <div class="position-relative" v-if="dashboardImageStatus">
                                  <a :href="linkToPage" target="_blank">
                                    <img class="img-fluid" :src="basePath+dashboardImageContent.image" v-if="dashboardImageContent.image"></a>
                                  <div class="overlaycstext imageContent card rounded-0">
                                    <div class="text-dark mb-0"  v-html="dashboardImageContent.content">
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div class="col-md-6 pr-0" v-if="subdomain == 'waterski'">
                                <div class="row">
                                    <div class="col-md-6 r-pad l-pad ">
                                        <router-link
                                            class=""
                                            to="/club/club-insurance-information"
                                        >
                                            <div class="bg-ins_prog mb-2 ">
                                                <div
                                                    class="card-header rm-border"
                                                >
                                                    <i
                                                        class="text-dark  far fa-address-card"
                                                    ></i>
                                                    <h3 class="text-dark">
                                                        Club Insurance
                                                        Information
                                                    </h3>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                    <div class="col-md-6 r-pad l-pad ">
                                        <router-link
                                            class=""
                                            to="/club/quick-link-resources"
                                        >
                                            <div class="bg-ins_prog mb-2 ">
                                                <div
                                                    class="card-header rm-border"
                                                >
                                                    <i
                                                        class=" text-dark  far fa-address-card"
                                                    ></i>
                                                    <h3 class="text-dark">
                                                        Quick Link Resources
                                                    </h3>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                    <div class="col-md-6 r-pad l-pad">
                                        <router-link
                                            class=""
                                            target="_blank"
                                            :to="{ name: 'atheletsafetysport' }"
                                        >
                                            <div class="bg-ins_prog mb-2 ">
                                                <div
                                                    class="card-header rm-border"
                                                >
                                                    <i
                                                        class="text-dark   fas fa-tags"
                                                    ></i>
                                                    <h3 class="text-dark">
                                                        Athlete Safety/Safe
                                                        Sport
                                                    </h3>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                    <div class="col-md-6 r-pad l-pad">
                                        <a
                                            href="/club/practice-event-sanctioning"
                                        >
                                            <div class="bg-ins_prog mb-2 ">
                                                <div
                                                    class="card-header rm-border"
                                                >
                                                    <i
                                                        class="text-dark  fas fa-dharmachakra"
                                                    ></i>
                                                    <h3 class="text-dark">
                                                        Practice and Event
                                                        Sanctioning
                                                    </h3>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-md-6 r-pad l-pad">
                                        <router-link
                                            class=""
                                            to="/club/club-event-merchandise"
                                        >
                                            <div class="bg-ins_prog mb-2 ">
                                                <div
                                                    class="card-header rm-border"
                                                >
                                                    <i
                                                        class="text-dark fas fa-tags"
                                                    ></i>
                                                    <h3 class="text-dark">
                                                        Club/Event Merchandise
                                                    </h3>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                    <div class="col-md-6 r-pad l-pad">
                                        <router-link
                                            class=""
                                            to="/club/award-trophy-discounts"
                                        >
                                            <div class="bg-ins_prog mb-2 ">
                                                <div
                                                    class="card-header rm-border"
                                                >
                                                    <i
                                                        class="text-dark fas fa-tags"
                                                    ></i>
                                                    <h3 class="text-dark">
                                                        Award/Trophy Discounts
                                                    </h3>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
            <div class="col-md-6">
            <div class="row clubdashboard">
                <div v-if="subdomain == 'shooting'" class="mdashicon col-md-12 r-pad pl-0">                
                  <div class=" bg-ins_prog2 mb-2 shadow-sm">
                    <div class="card-header rm-border">
                      <i class="fas fa-question text-dark"></i>                      
                      <h3 class="text-dark"> Are you interested in hosting a USAS-sanctioned match? Reach out to <a href="mailto:competitions@usashooting.org">competitions@usashooting.org</a> to request individualized Match Director access to the Events System or ask any questions about the process.</h3>
                    </div>
                  </div>               
              </div>
              <div v-if="subdomain == 'shooting'" class="col-md-12 r-pad pl-0">
                <a target="_blank" class="" href="https://ems.usashooting.org/event_list">
                  <div class=" bg-ins_prog2 mb-2 shadow-sm">
                    <div class="card-header rm-border">
                      <i class="fas fa-crosshairs text-dark"></i>
                      <h3 class="text-dark">Find out about upcoming USA Shooting events.</h3>
                    </div>
                  </div>
                </a>
              </div>

              <div v-if="subdomain == 'shooting'" class="col-md-6 pl-0">
                <a target="_blank" class="" href="https://store.usashooting.org/">
                  <div class="bg-ins_prog1 mb-2 shadow-sm" style="background: #fff;">
                    <div class="card-header rm-border">
                      <i class="fas fa-shopping-cart text-dark"></i>
                      <h3 class="text-dark">Merchandise</h3>
                    </div>
                  </div>
                </a>
              </div>
              <div v-if="subdomain == 'shooting'" class="col-md-6 r-pad l-pad">
                <a target="_blank" class="" href="https://www.usashooting.org/donate-20141126072420/donate-20200716163507">
                  <div class="bg-ins_prog1 mb-2 shadow-sm" style="background: #fff;">
                    <div class="card-header rm-border">
                      <i class="fas fa-donate text-dark"></i>
                      <h3 class="text-dark"> Donation</h3>
                    </div>
                  </div>
                </a>
              </div>
            <div v-if="subdomain == 'shooting'" class="col-md-12 pl-0 l-pad newsandannouncement">
              <div class="card">
              <!-- <div class="card-header">
                <h3 class="card-title">News & Announcements</h3>
                <div class="card-tools">
                  <button type="button" class="btn btn-tool" data-card-widget="collapse">
                    <i class="fas fa-minus"></i>
                  </button>                  
                </div>
              </div> -->
              <!-- /.card-header -->
              <div class="card-body p-0 news_annouce">
                <a target="_blank" href="https://www.usashooting.org/news" class="uppercase"><img style="width:100%;" class="img-fluid" src="/shooting/dashboard-news.jpg"></a>
                <span class="text-white newsannouncement">News &amp; Announcements</span>
                <!-- <ul class="products-list product-list-in-card pl-2 pr-2">
                  <li class="item pt-3 pb-3">
                    <div class="product-img">
                      <img :src="'/' + this.getSiteProps('login.logo')" alt="Product Image" class="img-size-50">
                    </div>
                    <div class="product-info pl-2">
                      <a target="_blank" href="https://www.usashooting.org/news/2021/8/11/1389-12-usa-shooting-athletes-receive-prestigious-issf-presidents-cup-invites" class="product-title text-dark">            
                      <span class="">
                         USA Shooting Athletes Receive Prestigious ISSF President's Cup Invites
                      </span><br>       
                      <span class="text-primary">08/11/2021</span>
                      </a>
                    </div>
                  </li>
                  <li class="item pt-3 pb-3">
                    <div class="product-img">
                      <img :src="'/' + this.getSiteProps('login.logo')" alt="Product Image" class="img-size-50">
                    </div>
                    <div class="product-info pl-2">
                      <a target="_blank" href="https://www.usashooting.org/news/2021/8/3/1387-six-olympic-medals-for-usa-shooting-best-performance-since-1964-" class="product-title text-dark">
                      <span class="">
                          Six Olympic Medals for USA Shooting, Best Performance Since 1964
                      </span><br>
                      <span class="text-primary">08/03/2021</span>
                      </a>
                    </div>
                  </li>
                </ul> -->
              </div>
              <!-- /.card-body -->
              <!-- <div class="card-footer bgevent text-center">
                <a target="_blank" href="https://www.usashooting.org/news" class="uppercase">View All News</a>
              </div> -->
              <!-- /.card-footer -->
            </div>
            </div>
            </div>
            </div>

                            <div class="col-md-4 r-pad l-pad "></div>
                            <div class="col-md-4 r-pad l-pad"></div>
                            <div class="col-md-4 r-pad l-pad"></div>
                        </div>
                    </div>
                </div>
            </div>
          <partner-season @partner-clicked="handlePartnerClicked"></partner-season>
          <div id="insurance_section" class="col-md-12 mt-3 mb-3" v-show="subdomain === 'alliancefastpitch'">
            <b-card body-class="pl-0 pt-0 pr-0 table-responsive">
              <b-card-header style="display: block;" class="border-bottom-0">
                <div class="profile-body">
                  <div class="mem-header">
                    <div class="header-title">
                      <h4>Insurance</h4>
                    </div>
                  </div>
                </div>
              </b-card-header>

              <b-card-body>
                <div class="row justify-content-center">
                    <a :href="'/team/insurance'">
                      <b-card img-src="/images/insurance_section.png" no-body border-variant="secondary" class="shadow-sm">
                      </b-card>
                    </a>
                  </div>
              </b-card-body>

            </b-card>
          </div>
          <b-modal
              id="signClubWaiver"
              title="Sign the Club Agreement"
              scrollable
              size="xl"
              hide-footer
              no-close-on-backdrop="true"
              no-close-on-esc="true"
              hide-header-close="true"
          >
            <b-card no-body>
              <b-tabs v-model="tabIndex" pills card id="signClubWaiverCard">
                <b-tab
                    v-for="(spsWaiver, idx) in waiverData"
                    :key="idx"
                    :title="'Club Agreement'"
                >
                  <div class="text-center mb-2">
                    <div>
                      <i>This club agreement is for your membership effective from </i>
                      <b>{{ spsWaiver.user_info.start_date }}</b> <i>to </i>
                      <b>{{ spsWaiver.user_info.expires_at }}</b>
                    </div>
                  </div>
                  <p v-html="spsWaiver.waivers_content"></p>
                  <div>
                    <span class="mb-2 mt-2" style="display:block;">I hereby acknowledge that:</span>
                    <ul>
                      <li>I am authorized to sanction an event on behalf of the Local Organizing Club (LOC); and</li>
                      <li>I have reviewed the sanctioning agreement and that the LOC agrees to its terms.</li>
                    </ul>
                  </div>
                  <p class="font-13 mt-4 text-danger"><em>Please check the box and initial in the space provided.</em></p>
                  <div class="form-inline">
                    <b-form-checkbox v-model="clubWaiverUpd.agree" value="1" unchecked-value="0" >I,
                      <b-form-input v-model="clubWaiverUpd.member_initials" size="sm" maxlength="2" style="width: 40px;" ></b-form-input>
                      have read and accept the {{ prop_general.site_name }} club agreement dated on:
                      <b-form-input v-model="waiverCurrentDtTime" size="sm" disabled></b-form-input>
                    </b-form-checkbox>
                  </div>
                  <span class="text-sm text-danger">{{ waiverErrors.agree }}</span><br>
                  <span class="text-sm text-danger">{{ waiverErrors.initials }}</span>
                  <div class="row mt-3">
                    <div class="col-md-12">
                      <label><strong>Submitted by</strong></label>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="form-label">First Name</label>
                        <input type="text" class="form-control" maxlength="45" placeholder="Dickerson" name="clubSubmittedBy.first_name" v-model="clubWaiverUpd.first_name">
                        <span class="text-sm text-danger">{{ waiverClubErrors.first_name }}</span>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="form-label">Last Name</label>
                        <input type="text" class="form-control" maxlength="45" placeholder="Macdonald" name="clubSubmittedBy.last_name" v-model="clubWaiverUpd.last_name">
                        <span class="text-sm text-danger">{{ waiverClubErrors.last_name }}</span>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="form-label">Club Position</label>
                        <input type="text" class="form-control" maxlength="45" placeholder="President" name="clubSubmittedBy.position" v-model="clubWaiverUpd.position">
                        <span class="text-sm text-danger">{{ waiverClubErrors.position }}</span>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label class="form-label">Email</label>
                        <input type="text" class="form-control" maxlength="45" placeholder="" name="clubSubmittedBy.email" v-model="clubWaiverUpd.email">
                        <span class="text-sm text-danger">{{ waiverClubErrors.email }}</span>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
              <div class="text-right col-sm-12">
                <b-button-group class="mt-2 mb-2">
                  <b-button variant="info" @click="clubWaiverSubmit()">Sign Now</b-button>
                </b-button-group>
              </div>
            </b-card>
          </b-modal>




        </div>
      <b-modal
          id="confirm-modal"
          title="Confirmation"
          @hide="resetRenewConfirmation"
      >
        <div class="row" v-if="subdomain == 'waterski'">
          <div >
            <div class="form-check">
              <input type="checkbox" v-model="renewCheck" class="form-check-input" id="renewCheck">
              <label class="form-check-label" for="renewCheck">Yes, renew my membership for an additional 12 months. Please follow the below steps during the renewal process:</label>
              <span id="confirmation_alert"></span>
             <ul class="grassrootul backscreen-group">
                <li><i  class="fas fa-chevron-right"></i>Review and edit Club Information</li>
                <li><i  class="fas fa-chevron-right"></i>Update Primary Contact</li>
                <li><i  class="fas fa-chevron-right"></i>Review and edit Site Information</li>
                <li><i  class="fas fa-chevron-right"></i>Purchase Practice/Exhibition Sanctioning (Optional)</li>
                <li><i  class="fas fa-chevron-right"></i>Review any Certificates of Insurance on file (if applicable)</li>
                <li><i  class="fas fa-chevron-right"></i>Update Club Officers - President, Vice President, Secretary, Treasurer</li>
                <li><i  class="fas fa-chevron-right"></i>Add Junior Development Leader (if applicable)</li>
                <li><i  class="fas fa-chevron-right"></i>Add Show Ski Director and Assistant Director (if applicable)</li>
                <li><i  class="fas fa-chevron-right"></i>Add Safe Sport Coordinator (if applicable)</li>
              </ul>

            </div>
          </div>
        </div>
        <div v-else>
          <div class="form-check">
            <input type="checkbox" v-model="renewCheck" class="form-check-input" id="renewCheck">
            <label class="form-check-label" for="renewCheck" v-if="isNotalliancefastpitch">Yes, please renew my membership for an additional 12 months.</label>
            <label class="form-check-label" for="renewCheck" v-else>Yes, please renew my membership for the {{ renewSeasonYearText }} season.</label>
          <span id="confirmation_alert"></span>
          </div>
        </div>
        <template #modal-footer="{ ok, cancel}">
          <b-button variant="secondary" @click="cancel()">
            Cancel
          </b-button>
          <b-button variant="primary"  @click="handleRenewConfirmation">
            Continue
          </b-button>
        </template>
      </b-modal>
    </div>

</template>

<script>
import axios from 'axios';
import $ from 'jquery';
import PartnerSeason from "../../Member/Dashboard/PartnerSeason";
import moment from "moment";
import dashboard from "../../Member/Dashboard/dashboard";
export default {
    name: "dashboard",
    components: { PartnerSeason  },
    props: ['partners'],
    data(){
        return {
          dashboard_status:null,
            profileData: {
                info: [],
            },
          clubWaiverForModal:[],
          waiverData: {},
          renewCheck: false,
          waiverErrors: {
            agree: "",
            initials: "",
          },
          waiverCurrentDtTime: "",
          clubWaiverUpd: {
            member_initials: "",
            signed_member: "",
            first_name: "",
            last_name: "",
            position: "",
            email: "",
            agree: "",
            waivers_id: "",
          },
          waiverClubErrors: {
            first_name: "",
            last_name: "",
            position: "",
            email: "",
          },
          renewSeasonYearText: '',
          membershipExpiring:false,
          disableRenew:false,
          isNotalliancefastpitch:true,
          renewDisabled: false,
          dashboardImageContent:[],
          dashboardWelcomeContent:[],
          dashboardImageStatus:false,
          dashboardWelcomeStatus:false,
          linkToPage: ''
        };

    },
    methods: {
      memberDashboardImage() {
        axios.get(this.basePath+'api/cmsSection/'+1)
            .then(function (response) {
              response.data.data.forEach(content => {
                if (content.section_id == 1) {
                  this.dashboardWelcomeContent = content;
                  this.dashboardWelcomeStatus = true;
                } else if (content.section_id == 6) {
                  this.dashboardImageContent = content;
                  this.linkToPage = content.link;
                  this.dashboardImageStatus = true;
                }
              });
            }.bind(this))
            .catch(function (error) {
              console.log(error);
            });
      },
        renewMemberShip: function() {
            this.$bvModal.show("confirm-modal");
            //window.open("/club/clubrenewal", "_self");
        },
      handleRenewConfirmation: function() {
        if(this.renewCheck == true){
          let slug = "club";
          if(this.getSiteProps('general.admin-team')) slug = "team";
          window.open("/"+slug+"/renew", "_self");
        }else{
          $('#confirmation_alert').html("<p style='color:red;font-size:13px'>Please check the box</p>");
        }
      },
      resetRenewConfirmation:function()
      {
        this.renewData = 'renew';
      },
        loadMembersInfo() {
            axios
            .get(this.basePath + "api/club/info", {
                headers: this.clubHeaders,
            })
            .then((response) => {
                this.profileData.info = response.data.data;
                this.membershipExpiring = response.data.data.membershipExpiring;
                this.renewDisabled = response.data.data.renewal_disable;
                if(this.profileData.info.member.waiver_status === 0 && response.data.data.signWaiver){
                  axios.get(
                      this.basePath + "api/club/get_waiver/" + this.profileData.info.registration.id,
                      { headers:this.clubHeaders }
                  ).then((resp) => {
                    this.waiverData = resp.data.data;
                    for (let prop in this.waiverData[0].user_info) {
                      if ( Object.prototype.hasOwnProperty.call(this.clubWaiverUpd, prop) ) {
                        this.clubWaiverUpd[prop] = resp[prop];
                      }
                    }
                    this.clubWaiverUpd.waivers_id = this.waiverData[0].waivers_id;
                    this.clubWaiverUpd.agree = 0;
                  }).catch((err) => {
                    console.log("club waiver error");
                  });

                  this.$nextTick(() => { this.$bvModal.show("signClubWaiver"); });
                }
              this.renewSeasonYearText = this.profileData.info.renew_season_year_text;
            })
            .catch((error) => {
                console.log(error);
            });
        },
      clubWaiverSubmit(){
        this.waiverErrors.agree = "";
        this.waiverErrors.initials = "";
        if (this.validateWaiverSign()) {
          axios.post(this.basePath + "api/club/update_waiver/" + this.profileData.info.registration.id,
                  this.clubWaiverUpd,
                  { headers: this.clubHeaders }
              )
              .then((response) => {
                if (response.data.status == "success") {
                  this.$bvModal.hide("signClubWaiver");
                  this.alertMessage = "Waiver signed successfully";
                  this.displayAlert = true;
                }
                this.loadMembersInfo();
              })
              .catch((error) => {
                console.log(error);
              });
        }
      },
      validateWaiverSign(){
        const validClubWaiverInitials = this.requiredTextField(this.clubWaiverUpd.member_initials, 'Initials');
        const validClubWaiverFirstName = this.requiredTextField(this.clubWaiverUpd.first_name, 'First Name');
        const validClubWaiverLastName = this.requiredTextField(this.clubWaiverUpd.last_name, 'Last Name');
        const validClubWaiverPosition = this.requiredTextField(this.clubWaiverUpd.position, 'Club Position');
        const validClubWaiverEmail = this.requiredTextField(this.clubWaiverUpd.email, 'E-Mail');
        const agree = (this.clubWaiverUpd.agree == 1);

        this.waiverClubErrors.first_name = validClubWaiverFirstName.error;
        this.waiverClubErrors.last_name = validClubWaiverLastName.error;
        this.waiverClubErrors.position = validClubWaiverPosition.error;
        this.waiverClubErrors.email = validClubWaiverEmail.error;
        this.waiverErrors.initials = validClubWaiverInitials.error;
        this.waiverErrors.agree = (agree ? "" : "Please acknowledge by checking the box")
        if(!validClubWaiverFirstName.valid || !validClubWaiverLastName.valid || !validClubWaiverPosition.valid
            || !validClubWaiverEmail.valid || !validClubWaiverInitials.valid || !agree) {
          this.valid = false;
          return this.valid;
        }
        return true;
      },
      handlePartnerClicked(id) {
        this.$router.push({ name: "partners-team", params: { id: id } });
      },
    },
  updated() {
    this.dashboard_status = this.getSiteProps('memberdashboard.stats');
  },
  mounted(){
      if(this.subdomain == 'alliancefastpitch'){
        this.isNotalliancefastpitch = false;
      }
        this.loadMembersInfo();
      this.waiverCurrentDtTime = new Date().toLocaleString();
      this.memberDashboardImage();
    }
};
</script>